// common styles
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
// components styles

.page-404__container {
  @extend %flex-dc;
  gap: 20px;
}

.page-404__strong {
  font-size: 60;
  font-weight: 600;
  text-align: center;
}

.page-404__text {
  font-size: 20;
  font-weight: 600;
  text-align: center;
}

