:root {
  --color--light: #f0f0f0;
  --color--dark: #000;
  --color--accent: #26354a;
  --color--gray: #e0e7ef;
  --color--red: #900;
  --transition--100: .1s linear;
  --transition--200: .2s linear;
  --transition--300: .3s linear;
  --transition--400: .4s linear;
  --transition--500: .5s linear;
  --box-shadow: 0 2px 2px #00000038;
  --box-shadow--main: 0 .5px 1px #0000001a, 0 2px 6px #5a646d33;
  --box-shadow--active: 0 0 8px -2px #0000001a, 0 6px 20px -3px #0003;
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 8px;
  --text-indent: 40px;
}

.page-404__container {
  flex-direction: column;
  display: flex;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.page-404__container {
  gap: 20px;
}

.page-404__strong {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.page-404__text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

/*# sourceMappingURL=index.a8679804.css.map */
